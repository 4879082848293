<template>
    <UModal ref="mod" @update:open="(e) => !e ? emit('close'): null " :prevent-close="preventClose" class="font-nunito"
        :ui="{ content: 'sm:max-w-sm' }">
        <template #content>
             <UCard class="pt-4">
            <SignInComp @redirect="emit('redirect', $event)" @success="() => { preventClose = false; emit('success') }"
                @request-login="preventClose = true" @request-echec="preventClose = false" />

        </UCard>
        </template>
       
    </UModal>
</template>

<script lang="ts" setup>
    const mod = useTemplateRef('mod')
    const preventClose = ref(false)

    const emit = defineEmits<{ success: [], redirect: [fullpath: string], close: []  }>()


</script>

<style></style>